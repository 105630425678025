<template>
  <div>
    <p>
      Get ready for car racing action -- going live in the LEGO® Universe Closed-Beta soon!
      <router-link :to="{ name: 'beta' }">Sign up for BETA</router-link>
      if you haven’t already!
    </p>
    <p>
      First create a custom LEGO racecar for your minifig! In the game, you’ll choose from more than a hundred options to build a racer that shows off your creative style!
    </p>
    <!-- picE1863AF57C21A680DE122D7394997CE7.jpg -->
    <img class="h-265 rounded" src="@/assets/news-network/ready-race-1.jpg">
    <p>
      Hop into your speed machine and pit your racing skills against five other LEGO Universe players’! Fly off giant jumps, smash LEGO models and hit speed patches for fast power boosts!
    </p>
    <p>
      Go for the checkered flag on the Gnarled Forest racetrack and you’ll zoom into a booming pirate battle!
    </p>
    <!-- pic2A8975046B83DA1DC3CC19331E5AF1FD.jpg -->
    <img class="h-265 rounded" style="margin-top: -10px;" src="@/assets/news-network/ready-race-2.jpg">
    <p>
      Or blast around a racetrack that reaches into outer space! Blaze in and out of a giant spaceship that minifigs are building to get to new LEGO Universe worlds!
    </p>
    <!-- pic35C3C8FECAF05802936A918303BAFFD6.jpg -->
    <img class="h-265 rounded" src="@/assets/news-network/ready-race-3.jpg">
    <p>
      Finish first and you’ll win leaderboard glory and LEGO loot like hot new helmets and cool accessories for your racecar!
    </p>
    <p>
      Racing is another reason to rave about LEGO Universe—the first massively multiplayer online game (MMOG) for LEGO fans!
    </p>
    <p>
      <br><em><font size="1">
        <strong>Please note:</strong>
        All of these concept art creations are part of the creative process. There is no guarantee that they will appear in the game as depicted here.
      </font></em>
    </p>
  </div>
</template>
